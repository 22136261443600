const packsData = {
    0: {
        id:1,
        title: 'Pack démarrage',
        priceMonthly: '0',
        mostPopular: false,
        infosUser: "Gratuit",
        infosPayment: 'Sans carte bancaire',
        mentions: "",
        offers: [
            {
                name: 'Acces privilégié au stock E-Motors',
                isIncluded: true
            },
            {
                name: 'CRM client',
                isIncluded: false
            },
            {
                name: 'Gestion des appels',
                isIncluded: false
            },
            {
                name: 'Gestion des reprises véhicules',
                isIncluded: false
            },
            {
                name: 'Back office mobile',
                isIncluded: false
            },
            {
                name: 'Intégration marketplace',
                isIncluded: false,
                info: 'intégration de votre stock de véhicule en catalogue à destination des autres abonnés à la solution'
            },
            {
                name: 'Site internet 100% personnalisable et nom de domaine',
                isIncluded: false
            },
        ]
    },
    1: {
        id:2,
        title: 'Pack citadine',
        priceMonthly: '99',
        mostPopular: false,
        infosUser: "",
        infosPayment: "",
        mentions: "",
        offers: [
            {
                name: 'Acces privilégié au stock E-Motors',
                isIncluded: true
            },
            {
                name: 'CRM client',
                isIncluded: true
            },
            {
                name: 'Gestion des appels',
                isIncluded: true
            },
            {
                name: 'Gestion des reprises véhicules',
                isIncluded: true
            },
            {
                name: 'Back office mobile',
                isIncluded: true
            },
            {
                name: 'Intégration marketplace',
                isIncluded: true
            },
            {
                name: 'Site internet 100% personnalisable et nom de domaine',
                isIncluded: false
            },
        ],
        description: [
            'Intégrer votre propre stock directement sur la plateforme',
            'Gérer votre base client : créer vos fiches clients, devis, commandes et reprises pour suivre tout le parcours commercial de vos clients',
            'Organiser le rappel de vos clients depuis le module de gestion de rappel et ayez toutes les informations sur les dossiers de vos clients',
            'Soyez toujours prêts en transportant votre plateforme de gestion directement depuis vos smartphone et tablettes',
        ]
    },
    2: {
        id:3,
        title: 'Pack berline',
        priceMonthly: '159',
        mostPopular: true,
        infosUser: "Frais d’ouverture 120€",
        infosPayment: "",
        mentions: "",
        offers: [
            {
                name: 'Acces privilégié au stock E-Motors',
                isIncluded: true
            },
            {
                name: 'CRM client',
                isIncluded: true
            },
            {
                name: 'Gestion des appels',
                isIncluded: true
            },
            {
                name: 'Gestion des reprises véhicules',
                isIncluded: true
            },
            {
                name: 'Back office mobile',
                isIncluded: true
            },
            {
                name: 'Intégration marketplace',
                isIncluded: true
            },
            {
                name: 'Site internet 100% personnalisable et nom de domaine',
                isIncluded: true
            },
        ],
        description: [
            'Créer votre propre site internet 100% personnalisable et facile d\'utilisation',
            'Que ce soit les vôtres ou ceux d\'E-Motors choisissez en quelques cliques quels véhicules vous souhaitez mettre en avant sur votre site',
            'Augmenter votre visibilité grâce à ce canal digital incontournable et générer des leads en recevant des demandes de devis provenant de votre site',
            'Alimentez votre base client avec les personnes qui vous contactent depuis votre site',
            'Mettez votre site internet à vos couleurs en choisissant la charte graphique, les textes, les photos, les rubriques de votre espace Tetraweb',
        ]
    },
    3: {
        id:4,
        title: 'Pack Sport',
        mostPopular: false,
        infosUser: "Sur mesure",
        infosPayment: "",
        mentions: "",
        offers: [
            {
                name: 'Acces privilégié au stock E-Motors',
                isIncluded: true
            },
            {
                name: 'CRM client',
                isIncluded: true
            },
            {
                name: 'Gestion des appels',
                isIncluded: true
            },
            {
                name: 'Gestion des reprises véhicules',
                isIncluded: true
            },
            {
                name: 'Back office mobile',
                isIncluded: true
            },
            {
                name: 'Intégration marketplace',
                isIncluded: true
            },
            {
                name: 'Site internet 100% personnalisable et nom de domaine',
                isIncluded: true
            },
        ],
        description: [
            'Pack sur mesure incluant des fonctionnalités dont VOUS avez besoin !'
        ],
    },
}

export default packsData;

import React, {useState} from "react";
import '../styles/form.scss';

const Input = (props) => {
    const [inputValue, setinputValue] = useState(props.defaultValue ? props.defaultValue : '');

    const handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setinputValue(value);
        if(props.onchange){
            props.onchange(inputValue);
        }
}

    return (
        <input
            type={props.type}
            name={props.name}
            id={props.name}
            value={inputValue}
            className={`form-control ${props.className}`}
            placeholder={props.placeholder}
            required={props.required}
            onChange={handleChange}
            onBlur={handleChange}
            aria-label={props.label ? props.label : props.name}
            pattern={props.pattern ? props.pattern : null}
        />
    )
}

export default Input;

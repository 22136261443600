import React, {useState} from "react";
import Modal from './modal';
import '../styles/footer.scss';
import Contact from "./contact";

const Footer = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(false);
    return (
        <footer>
            <div className="container">
                <div className="summary">
                    <h4>Aussiito en quelques mots...</h4>
                    <p>En étroite collaboration depuis plusieurs années avec la société E-Motors, mandataire automobile de renom, nous avons développé un outil CRM et PGI/ERP performant prévu pour répondre aux activités des professionnels de l’automobile...</p>
                    <a href="https://aussiito.fr/notre-solution" target="_blank" rel="noreferrer" className={'link-aussiito'}>Découvrir AUSSIITO</a>
                </div>
                <div className="address">
                    <div className="infos">
                        <h4>Coordonnées</h4>
                        <a href="mailto:contact@aussiito.fr">contact@aussiito.fr</a>
                        <a href="tel:+330176245245">01 76 245 245</a>
                    </div>
                </div>
                <div className="demo">
                    <div className="content">
                        <h4>Faites-vous un avis !</h4>
                        <button className={"btn"} onClick={() => setShow(true)}>Demandez une démo</button>
                        {show &&
                            <Modal show={show} close={handleShow}>
                                <Contact formName={'demo'}/>
                            </Modal>
                        }
                    </div>
                </div>
                <small>© {new Date().getFullYear()} - <a href="https://aussiito.fr/" title="Aussiito site officiel" target="_blank" rel="noreferrer">Aussiito</a> - <a href="https://aussiito.fr/mentions-legales" target="_blank" rel="noreferrer" title="Les mentions légales d'Aussiito">Mentions légales</a></small>
            </div>
        </footer>
    )
}


export default Footer;

import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import '../styles/modal.scss';

const Modal = props => {
    const root = document.getElementById('___gatsby');
    const el = document.createElement('div');
    useEffect(() => {
        root.appendChild(el);
        if (props.show){
            document.body.style.overflow = 'hidden';
        }
        return () => {
            root.removeChild(el);
            document.body.style.overflow = 'initial';
        };
    }, [props.show, el, root] );
    return ReactDOM.createPortal(
        <>
            {props.show &&
            <>
                <div className="overlay" onClick={props.close} role={"presentation"}></div>
                <div className={`modal`}>
                    <button className={'btn-close'} aria-label={'close'} onClick={props.close}></button>
                    {props.children}
                </div>
            </>
            }
        </>,
        el
    );
}

export default Modal;

import React, {useState} from "react";
import {Link} from "gatsby";
import Modal from './modal';
import '../styles/nav.scss';
import Contact from "./contact";

const Nav = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(false);
    return (
        <nav>
            <ul>
                <li>
                    <Link
                        to="/"
                        activeClassName="active"
                    >
                        Accueil
                    </Link>
                </li>
                <li>
                    <Link
                        to="/#prices"
                        activeClassName="active"
                    >
                        Tarifications
                    </Link>
                </li>
                <li>
                    <Link
                        to="/our-solutions"
                        activeClassName="active"
                    >
                        Solutions
                    </Link>
                </li>
                <li>
                    <Link
                        to="/about-us"
                        activeClassName="active"
                    >
                        À propos de nous
                    </Link>
                </li>
                <li>
                    <button className={'btn btn-modal'} onClick={() => setShow(true)}>Démarer</button>
                    {show &&
                        <Modal show={show} close={handleShow}>
                            <Contact formName={'signin'}/>
                        </Modal>
                    }
                </li>
            </ul>
        </nav>
    )
}


export default Nav;

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react";
import Nav from "./nav";
import Logo from '../images/logo-aussiito.png';
import '../styles/header.scss';

const Header = ({ siteTitle }) => (
  <header
  >
      <div className="container">
          <div className={'logo'}
          >
              <Link
                  to="/"
              >
                  <img src={Logo} alt="Aussiito, site pro"/>
              </Link>
          </div>
          <Nav/>
      </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

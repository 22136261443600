import React, {Fragment, useState} from "react";
import Input from './input';
import Select from 'react-select';
import packsData from '../packsData';
import '../styles/form.scss';

const Form = (props) => {
    const [contact, setContact] = useState({offer: props.offerName ? props.offerName : ''});
    const [userMessage, setUserMessage] = useState('');
    const [isToggleOffer, setIsToggleOffer] = useState(false);
    const [offerChoice, setOfferChoice] = useState(props.offerChoice);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const offers = [];
    Object.values(packsData).forEach(offer => {
        offers.push({value: offer.title, label: `${offer.title} - ${offer.priceMonthly}€ / mois`})
    })

    const customStyles = {
        container: () => ({
            width: '100%',
            border: '1px solid #2582AB',
            borderRadius: '0.25rem',
            position: 'relative'
        }),
        control: () => ({
            width: '100%',
            display: 'flex',
        }),
        menu: () => ({
           margin: '0.25rem 0 0',
           position: 'absolute',
           left: 0,
           right: 0,
           background: '#ffffff',
           borderRadius: '0 0 0.25rem 0.25rem',
           zIndex: 1,
           boxShadow: '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)',
           overflow: 'hidden'
        }),
        menuList: () => ({
           padding: 0,
           maxHeight: '300px',
           overflowY: 'auto',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#2582AB' : '#ffffff',
            color: state.isFocused ? '#ffffff' : '#313538',
            cursor: 'pointer',
            fontSize: '0.85rem',
            padding: '1rem',
        }),
        placeholder: () => ({
           fontSize: '0.85rem',
           color: 'rgba(49, 53, 56, 0.8)',
           opacity: 0.54
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return { ...provided, opacity, transition };
        }
    }

    const handleChangeMessage = (event) =>{
        const value = event.target.value;
        setUserMessage(value);
        setContact({...contact, message:value});
    }

    const handleToggleOffer = (event) => {
        event.preventDefault();
        setIsToggleOffer(!isToggleOffer);
    }

    const handleChangeOffer = (value) => {
        setOfferChoice(value.label);
        setContact({...contact, offer:value.value});
    }

    const handleChange = (value, name) => {
        setContact({...contact, [name]:value});
    }

    const handleSubmitContact = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const formData = new FormData(event.target)
        if(offerChoice){
            formData.append('contract', offerChoice)
        }

        fetch('https://pro.aussiito.fr/contact.php', {
            method: "POST",
            body: formData
        })
            .then(() => {
                const {onSuccess = () => {}} = props;
                onSuccess();
            })
            .catch(alert).finally(() => {
                setIsSubmitting(false);
            })
    }

    return (
        <form className={'form-modal'} onSubmit={(event) => handleSubmitContact(event)}>
            {
                props.formName === "signin" &&
                <div className="container-select full">
                    <Select
                        options={offers}
                        styles={customStyles}
                        placeholder={'Choisissez votre formule'}
                        onChange={(value) => handleChange(value.value, 'offer')}
                        required
                        name="contract"
                    />
                </div>
            }
            {
                props.formName === "pricing" &&
                    <div className={'offer-name full'}>
                        <p>Vous avez choisi la formule:</p>
                        {isToggleOffer ?
                            <Fragment>
                                <div className={'select-control'}>
                                    <Select
                                        options={offers}
                                        styles={customStyles}
                                        placeholder={'Choisissez votre formule'}
                                        onChange={(offer) => handleChangeOffer(offer, 'offer')}
                                        name="contract"
                                    />
                                </div>
                                <button className={'btn btn-choice'} onClick={(event) => handleToggleOffer(event)}>Valider</button>
                            </Fragment>
                            : <Fragment>
                                <span>{offerChoice}</span>
                                <button className={'btn btn-edit'} onClick={(event) => handleToggleOffer(event)}>Changer</button>
                            </Fragment>
                        }
                    </div>
            }
            <Input
                type="text"
                name="userCompany"
                id="userCompany"
                placeholder={'Raison sociale'}
                className={'third'}
                required={true}
                onchange={value => handleChange(value, 'company')}
            />
            <Input
                type="text"
                name="userLastName"
                id="userLastName"
                placeholder={'Nom'}
                className={'third'}
                required={true}
                onchange={value => handleChange(value, 'lastName')}
            />
            <Input
                type="text"
                name="userFirstName"
                id="userFirstName"
                placeholder={'Prénom'}
                className={'third'}
                required={true}
                onchange={value => handleChange(value, 'firstName')}
            />
            <Input
                type="email"
                name="userEmail"
                id="userEmail"
                defaultValue={props.emailValue}
                className={'half'}
                placeholder={'Email'}
                required={true}
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                onchange={value => handleChange(value, 'email')}
            />
            <Input
                type="tel"
                name="userPhone"
                id="userPhone"
                className={'half'}
                placeholder={'Téléphone'}
                required={true}
                pattern='[0-9]+'
                onchange={value => handleChange(value, 'phone')}
            />
            {
                props.formName === "demo" &&
                    <textarea
                        id={'userMessage'}
                        name={'userMessage'}
                        placeholder={'Ecrivez votre message'}
                        onChange={handleChangeMessage}
                        value={userMessage}
                        className={'form-control full'}
                    />
            }
            <div className={'full container-rgpd'}>
                <Input
                    type="checkbox"
                    name="rgpdMention"
                    id="rgpdMention"
                    required={true}
                    onchange={value => handleChange(value, 'rgpdConsentment')}
                />
                <label htmlFor="rgpdMention">
                    En soumettant ce formulaire, j'accepte que mes informations soient utilisées exclusivement dans le cadre de ma demande.</label>
            </div>
            <button type={'submit'} disabled={isSubmitting} className={'btn btn-submit'}>Valider</button>
        </form>
    )
}

export default Form;

import React, {useState} from 'react';
import Form from "./form";

const Contact = ({ formName, offerChoice = null, offerName = null, emailValue = null }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const handleSuccess = () => {
        setIsSuccess(true);
    }

    if (isSuccess) {
        return (
            <>
                <h1>Votre demande a bien été prise en compte</h1>
                <p>
                    Votre commercial vous recontactera dans les plus bref délais.< br/>
                    Pour plus d'informations, vous pouvez contacter Maxime au <b>07.49.18.06.71</b>.
                </p>
            </>
        )
    }

    return (
        <>
            <h3 className={'title'}>Démarrer</h3>
            <Form
                formName={formName}
                offerChoice={offerChoice}
                offerName={offerName}
                emailValue={emailValue}
                onSuccess={handleSuccess}
            />
        </>
    )
};

export default Contact;